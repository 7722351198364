//@ts-check
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import arkadu from "src/coreConfig";
import useWebSocket from "src/hooks/useWebSocket";
import { setCustomerItem } from "src/slices/customers";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NotAuthorizedView from "src/views/errors/NotAuthorizedView";
const CustomerContext = createContext();

const CustomerLayout = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendWs, lastMessage, wsMessages } = useWebSocket(
    arkadu.urls.webSocket.customers
  );
  const { currentCustomer, customerPermissions } = useSelector(
    (state) => state.customers
  );
  const { user } = useSelector((state) => state.users);
  const { adminMode } = useSelector((state) => state.settings);

  const permCheck = (action, module) => {
    return checkPermission({
      user: user,
      customerId: currentCustomer.id,
      action: action,
      module: module,
    });
  };
  useEffect(() => {
    if (adminMode && currentCustomer && user) {
      dispatch(
        setCustomerItem({
          itemName: "customerPermissions",
          data: {
            viewCustomer: permCheck("can_view_customer", "nets_customers"),
            changeCustomer: permCheck("can_change_customer", "nets_customers"),
            addMember: permCheck("can_add_member", "nets_customers"),
            changeMember: permCheck("can_change_customer", "nets_customers"),
            viewMemberLogs: permCheck("can_view_userlog", "nets_nets_user"),
            changeMemberRoles: permCheck("can_add_role", "nets_user"),
            addDepartment: permCheck("can_add_department", "nets_corporate"),
            addStaff: permCheck("can_add_staff", "nets_corporate"),
            addSchedule: permCheck("can_add_schedule", "nets_corporate"),
          },
        })
      );
    }
  }, [adminMode, currentCustomer, user]);

  if (!customerPermissions.viewCustomer) {
    return (
      <NotAuthorizedView
        message={t(
          "No tiene permiso acceso. Solicite a su superior si considera que debería poder ingresar."
        )}
      />
    );
  }

  return (
    <CustomerContext.Provider value={{ permCheck }}>
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext };
export default CustomerLayout;
