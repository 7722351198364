import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LottieAnimation from "src/components/LottieAnimation";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import animationData from "src/lotties/arkadu_planning_animation";
import { setPlanningState } from "src/slices/planning";
import { useDispatch, useSelector } from "src/store";

const PlanningLayout = ({ children }) => {
  const { projects } = useSelector((state) => state.planning);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const { res, requestData, isLoading } = useAsyncRequest();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentCustomer && !projects) {
      requestData(
        arkadu.urls.v2.planning.handle,
        {
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
          instance: "project",
          action: "list",
        },
        {},
        t("Proyectos")
      );
    }
  }, [projects, currentCustomer]);

  useEffect(() => {
    if (res) {
      dispatch(setPlanningState({ projects: res.data }));
    }
  }, [res]);

  if (!currentCustomer || !projects) {
    return <LottieAnimation animationData={animationData} />;
  }
  return <div>{children}</div>;
};

export default PlanningLayout;
