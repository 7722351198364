import { lazy } from "react";
import BaseLayoutId from "src/views/arkadu/BaseLayoutId";

export default [
  {
    exact: true,
    layout: BaseLayoutId,
    path: [
      "/:customerName/suppliers/detail/:id/:tabName",
      "/:customerName/suppliers/detail/:id/",
    ],
    component: lazy(() => import("src/views/arkadu/suppliers/SupplierDetail")),
  },
  {
    exact: true,
    path: ["/:customerName/suppliers/:tabName/", "/:customerName/suppliers/"],
    component: lazy(() => import("src/views/arkadu/suppliers/SuppliersIndex")),
  },
];
