import { server } from "src/coreConfig";
import { setAIState } from "src/slices/ai";
import { useDispatch, useSelector } from "src/store";
import useAsyncRequest from "./useAsyncRequest";
const apiPath = "/api/v2/ai";
const iaUrls = {
  ia: {
    chat: server + apiPath + "/chat/",
    generateContent: server + apiPath + "/generateContent/",
    clearChat: server + apiPath + "/clearChat/",
  },
};

const useAI = () => {
  const { messages } = useSelector((state) => state.ai);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { api } = useAsyncRequest();
  const dispatch = useDispatch();

  const generateContent = async (message, role) => {
    console.log("generateContent", message, role, messages);
    dispatch(
      setAIState({ messages: [{ message, from: "user" }, ...messages] })
    );

    try {
      const res = await api(iaUrls.ia.generateContent, {
        message,
        role,
        customer_id: currentCustomer.id,
      });

      console.log("generateContent", res.data.data, messages);
      dispatch(
        setAIState({
          iaData: res.data.data,
          messages: [
            {
              message: res.data.data,
              from: "ia",
            },
            ...messages,
          ],
        })
      );
      return { res: 1, message: "Content generated", data: res.data.data };
    } catch (error) {
      console.error("generateContent", error);
      return { res: 0, message: error.message };
    }
  };

  const chatMessage = async (message, role) => {
    dispatch(
      setAIState({ messages: [{ message, from: "user" }, ...messages] })
    );
    try {
      const res = await api(iaUrls.ia.chat, {
        message,
        role,
        customer_id: currentCustomer.id,
      });
      dispatch(
        setAIState({
          messages: [{ message: res.data.data, from: "ia" }, ...messages],
        })
      );
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const clearChat = async () => {
    dispatch(setAIState({ messages: [] }));
    try {
      await api.post(iaUrls.ia.clearChat);
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };

  const setInitialMessage = async (message) => {
    try {
      dispatch(
        setAIState({
          messages: [
            {
              message: message,
              from: "ia",
            },
          ],
        })
      );
    } catch (error) {
      return { res: 0, message: error.message };
    }
  };
  return {
    generateContent,
    chatMessage,
    clearChat,
    setInitialMessage,
  };
};

export default useAI;
