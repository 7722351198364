import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";
import { media_url } from "src/coreConfig";

const useStyles = makeStyles(() => ({
  root: {},
  date: {
    marginLeft: 6,
  },
  media: {
    height: 150,
    backgroundPosition: "top",
  },
}));

function CustomerCard(props) {
  const classes = useStyles();
  const { currentCustomer, className, ...rest } = props;

  return (
    <Box p={2}>
      <Grid container spacing={2} alignContent="center">
        <Grid item sm={12}>
          <img
            src={media_url(currentCustomer.logo)}
            style={{ maxWidth: "100%", borderRadius: "10px" }}
          />
        </Grid>
        <Grid item sm={12}>
          <Typography variant="body1" align="center">
            {currentCustomer.name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

CustomerCard.propTypes = {
  className: PropTypes.string,
  currentCustomer: PropTypes.object.isRequired,
};

export default CustomerCard;
