const { checkPermission } = require("src/slices/users");
const { useSelector } = require("src/store");

export function useCanDo(module) {
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);

  const canDo = (action, mod) => {
    return (
      adminMode &&
      checkPermission({
        user: user,
        action: action,
        module: mod || module,
        customerId: currentCustomer?.id || null,
      })
    );
  };
  return {
    canDo: canDo,
  };
}
