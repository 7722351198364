import React from "react";
import LoadingScreen from "src/components/LoadingScreen";
import useSocialResp from "src/hooks/useSocialResp";
import { useSelector } from "src/store";

const SocialRespLayout = ({ children }) => {
  const {} = useSocialResp();
  const { socialRespCategories } = useSelector(
    (state) => state.socialResponsibility
  );

  if (!socialRespCategories) {
    return <LoadingScreen message="Cargando categorías..." />;
  }

  return <div>{children}</div>;
};
export default SocialRespLayout;
