import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import useMotor from "src/hooks/useMotor.ts";
import { useSelector } from "src/store";

const VehicleMotorLayout = ({ children }) => {
  const { vehicleUses, vehicleTypes, motorInitialized } = useSelector(
    (state) => state.motor
  );
  const {} = useMotor();
  const { t } = useTranslation();

  if (!motorInitialized) {
    return <LoadingScreen message={t("Cargando opciones de vehículos")} />;
  }
  return <div>{children}</div>;
};
export default VehicleMotorLayout;
