//@ts-check
import { useSnackbar } from "notistack";
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import useWebSocket from "src/hooks/useWebSocket";
import {
  getServiceActivities,
  initialStateUC,
  setServicesPermissions,
  setServicesState,
  setServiceTypes,
} from "src/slices/services";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const ServicesContext = createContext();

const ServicesLayout = (props) => {
  const { children, ...rest } = props;
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { subscriptions, serviceTypes, serviceFees } = useSelector(
    (state) => state.services
  );

  const { user } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { api } = useAsyncRequest();
  const { sendWs, lastMessage, wsMessages } = useWebSocket(
    arkadu.urls.webSocket.service
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getServices = async () => {
    try {
      const res = await axiosInstance.post(
        arkadu.urls.v2.service.serviceTypes.list,
        {
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        }
      );
      if (res.data.res === 1) {
        dispatch(setServiceTypes(res.data.data));
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t("Error"), { variant: "error" });
    }
  };

  const getSectors = async () => {
    try {
      const res = await axiosInstance.post(arkadu.urls.v2.service.sectorList, {
        customer_id: currentCustomer.id,
      });
      if (res.data.res === 1) {
        dispatch(
          setServicesState({
            serviceSectors: res.data.data,
          })
        );
      } else {
        enqueueSnackbar(res.data.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(t("Error"), { variant: "error" });
    }
  };

  useEffect(() => {
    if (user && !adminMode && currentCustomer) {
      sendWs(
        JSON.stringify({
          type: "uc_request",
          message: {
            type: "get_subscriptions",
            customer_id: currentCustomer.id,
          },
        })
      );
    }
    if (user && adminMode && currentCustomer) {
      dispatch(getServiceActivities(currentCustomer));
      dispatch(
        setServicesPermissions({
          dashboard: checkPermission({
            user: user,
            action: "can_view_dashboard",
            module: "nets_service",
            customerId: currentCustomer.id,
          }),
          addService: checkPermission({
            user: user,
            action: "can_add_servicetype",
            module: "nets_service",
            customerId: currentCustomer.id,
          }),
          addServiceValue: checkPermission({
            user: user,
            action: "can_add_servicetypevalue",
            module: "nets_service",
            customerId: currentCustomer.id,
          }),
          addCommunity: checkPermission({
            user: user,
            action: "can_add_servicesector",
            module: "nets_service",
            customerId: currentCustomer.id,
          }),
          addCustomer: checkPermission({
            user: user,
            action: "can_add_serviceclient",
            module: "nets_service",
            customerId: currentCustomer.id,
          }),
        })
      );
    } else {
      dispatch(setServicesPermissions(initialStateUC.servicesPermissions));
    }
  }, [user, adminMode, currentCustomer]);

  const getServiceFees = async () => {
    var _r = await api(
      arkadu.urls.v2.service.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "service_fee",
        action: "list",
      },
      {},
      null
    );
    if (_r.data.res === 1) {
      dispatch(setServicesState({ serviceFees: _r.data.data }));
    }
  };

  useEffect(() => {
    if (!serviceTypes && currentCustomer) {
      getServices();
      getSectors();
    }
  }, [serviceTypes, currentCustomer]);

  useEffect(() => {
    if (!serviceFees && currentCustomer) {
      getServiceFees();
    }
  }, [serviceFees]);

  if (!currentCustomer || !serviceFees || !serviceTypes) {
    return <LoadingScreen message={t("cargando componentes")} />;
  }
  return (
    <ServicesContext.Provider value={{}}>{children}</ServicesContext.Provider>
  );
};
export { ServicesContext };
export default ServicesLayout;
