import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/eventads/request/:id"],
    component: lazy(() =>
      import("src/views/arkadu/eventads/EventadsAdRequestDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/eventads/company/:id"],
    component: lazy(() =>
      import("src/views/arkadu/eventads/EventadsAdsUserIndex")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/eventads/:tabName", "/:customerName/eventads/"],
    component: lazy(() => import("src/views/arkadu/eventads/EventadsIndex")),
  },
];
