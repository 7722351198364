import React, { useEffect, useState } from "react";
import arkadu from "src/coreConfig";
import { setSuppliersState } from "src/slices/suppliers";
import { useDispatch, useSelector } from "src/store";
import axios from "src/utils/axios";

const SuppliersLayout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const { suppliersCategories } = useSelector((state) => state.suppliers);
  const dispatch = useDispatch();

  const loadSuppliersCategories = async () => {
    if (isLoading) return;
    setIsLoading(true);
    var res = await axios.post(arkadu.urls.v2.supplier.handle, {
      instance: "supplier_category",
      action: "list",
      customer_id: currentCustomer.id,
      mode: adminMode ? "admin" : "user",
    });
    setIsLoading(false);
    if (res.data.res === 1) {
      dispatch(setSuppliersState({ suppliersCategories: res.data.data }));
    }
  };

  useEffect(() => {
    if (!suppliersCategories) {
      loadSuppliersCategories();
    }
  }, [suppliersCategories]);
  return <React.Fragment>{children}</React.Fragment>;
};

export default SuppliersLayout;
