import { lazy } from "react";

export default [
  {
    exact: true,
    path: [
      "/:customerName/system/dev/request/:requestId",
      "/:customerName/system/dev/request/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/dev/parts/DevRequestDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/system/dev/:tabName", "/:customerName/system/dev/"],
    component: lazy(() => import("src/views/arkadu/dev/DevIndex")),
  },
];
