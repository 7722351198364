import React, { createContext } from "react";

const ShopContext = createContext();

const ShopLayout = ({ children, ...rest }) => {
  // const { sendWs } = useWebSocket(arkadu.urls.webSocket.shop);

  return <ShopContext.Provider value={{}}>{children}</ShopContext.Provider>;
};

export { ShopContext };
export default ShopLayout;
