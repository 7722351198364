//@ts-check
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import arkadu from "src/coreConfig";
import { setEventadsState } from "src/slices/eventads";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const EventadsLayout = ({ children }) => {
  const { adsStore } = useSelector((state) => state.eventads);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loadAdsStore = async () => {
    try {
      var res = await axiosInstance.post(arkadu.urls.v2.eventads.ad.list, {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      closeSnackbar();
      if (res.data.res === 1) {
        dispatch(setEventadsState({ adsStore: res.data.data }));
      } else {
        console.log(res.data);
        enqueueSnackbar(
          (res.data && res.data.message) || "Error cargando adsStore",
          { variant: "error" }
        );
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar(
        (e.data && e.data.message) || e.statusText || t("Error"),
        {
          variant: "error",
        }
      );
    }
  };
  useEffect(() => {
    if (currentCustomer && !adsStore) {
      loadAdsStore();
    }
  }, [adsStore, currentCustomer]);
  return <>{children}</>;
};

export default EventadsLayout;
