//@ts-check
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { setAuthorized } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import SecurityPinInline from "src/views/forms/user/SecurityPinInline";
import CustomDialogTitle from "./CustomDialogtitle";

const Dialogs = ({ value, dialogs, onClose }) => {
  const initialized = useRef(null);
  const { authorized } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialized.current === undefined) {
      initialized.current = true;
    }
  }, [initialized]);
  if (!value) {
    return null;
  }
  return (
    <>
      {dialogs.map((d, j) => {
        return (
          <Dialog
            key={`${d.value}-${j}`}
            maxWidth={d.size}
            open={Boolean(value === d.value)}
            fullWidth
            fullScreen={d.fullScreen}
            onClose={onClose}
          >
            <CustomDialogTitle
              onClose={onClose}
              avatar={d.icon || null}
              color={d.color}
            >
              {d.title}
            </CustomDialogTitle>
            <DialogContent sx={{ paddingTop: "20px !important" }}>
              {d.authorizationRequired && !authorized ? (
                <SecurityPinInline
                  onAuthorized={() => dispatch(setAuthorized(true))}
                />
              ) : (
                <>{d.body}</>
              )}
            </DialogContent>
            <DialogActions>
              {d.actions &&
                d.actions.map((a, j) => {
                  if (!a) {
                    return;
                  }
                  const { label, ...props } = a;
                  return (
                    <Button key={`${j}-b`} {...props}>
                      {label}
                    </Button>
                  );
                })}
            </DialogActions>
          </Dialog>
        );
      })}
    </>
  );
};

Dialogs.propTypes = {
  value: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  dialogs: PropTypes.arrayOf(
    PropTypes.shape({
      fullScreen: PropTypes.bool,
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
      body: PropTypes.element,
      icon: PropTypes.any,
      color: PropTypes.string,
      authorizationRequired: PropTypes.bool,
      actions: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              label: PropTypes.string.isRequired,
              onClick: PropTypes.func,
              variant: PropTypes.oneOf(["text", "outlined", "contained"]),
              startIcon: PropTypes.element,
              color: PropTypes.oneOf([
                "inherit",
                "primary",
                "secondary",
                "success",
                "error",
                "info",
                "warning",
              ]),
            }),
            PropTypes.bool,
            null,
            undefined,
          ])
        ),
        
        PropTypes.bool,
      ]),
    }).isRequired
  ),
};
Dialogs.defaultProps = {
  dialogs: {
    fullScreen: false,
    size: "md",
    color: "info",
    authorizationRequired: false,
  },
};
export default Dialogs;
