//@ts-check
import { DoneRounded, ImageRounded } from "@mui/icons-material";
import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 50,
        height: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AvatarUploader = ({ onSelect, aspect }) => {
  const [selectedFile, setSelectedFile] = useState();
  const { t } = useTranslation();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [preview, setPreview] = useState();
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [dataCropped, setDataCropped] = useState(null);
  const [filename, setFilename] = useState("");
  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      console.log(width, height);
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const handleFileChange = (e) => {
    setCrop(undefined);
    const file = e.target.files[0];
    setFilename(file.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      //   onSelect(e.target.result);
      setSelectedFile(file);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (completedCrop) {
      const canvas = previewCanvasRef.current;
      const image = new Image();
      image.src = preview;
      image.onload = () => {
        canvas.width = completedCrop.width;
        canvas.height = completedCrop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          completedCrop.x,
          completedCrop.y,
          completedCrop.width,
          completedCrop.height,
          0,
          0,
          completedCrop.width,
          completedCrop.height
        );
        setDataCropped(canvas.toDataURL());
        // onSelect(canvas.toDataURL());
      };
    }
  }, [completedCrop]);
  return (
    <Grid container spacing={2} sx={{ display: "flex" }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <Grid item xs={12}>
        <ButtonGroup>
          <Button variant="outlined" color="info" startIcon={<ImageRounded />}>
            <label htmlFor="raised-button-file">
              {t("Seleccionar Imagen")}
            </label>
          </Button>

          {dataCropped && (
            <Button
              startIcon={<DoneRounded />}
              onClick={() => onSelect(dataCropped, filename)}
              variant="outlined"
              color="info"
            >
              {t("Listo")}
            </Button>
          )}
        </ButtonGroup>
      </Grid>
      {/* image preview */}
      <Grid>
        {preview && (
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            aspect={1}
            onComplete={(c) => setCompletedCrop(c)}
          >
            <img
              style={{
                transform: `scale(${1}) rotate(${0}deg)`,
              }}
              //   style={{ width: "350px", height: "350px" }}
              src={preview}
              alt="preview"
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </Grid>
      <Grid sx={{ padding: "10px" }}>
        {!!completedCrop && (
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{t("Vista previa")}</Typography>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
            <Button
              startIcon={<DoneRounded />}
              onClick={() => {
                onSelect(dataCropped, filename);
              }}
              variant="outlined"
              color="info"
            >
              {t("Listo")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AvatarUploader;
