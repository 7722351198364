import { lazy } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import BaseLayoutId from "src/views/arkadu/BaseLayoutId";

export default [
  {
    exact: true,
    layout: BaseLayoutId,
    path: "/:customerName/poll/detail/:id",
    component: lazy(() => import("src/views/arkadu/poll/PollDetail")),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: "/:customerName/poll/answer/:id",
    component: lazy(() => import("src/views/arkadu/poll/PollAnswer")),
  },
  {
    exact: true,
    path: "/:customerName/poll",
    component: lazy(() => import("src/views/arkadu/poll/PollIndex")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
