//@ts-check
import { AddRounded } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormBuilder from "src/components/formBuilder/FormBuilder";
import LoadingScreen from "src/components/LoadingScreen";
import PinAuthorizedView from "src/components/PinAuthorizedView";
import arkadu from "src/coreConfig";
import useAsyncRequest from "src/hooks/useAsyncRequest";
import { useCanDo } from "src/hooks/useCando";
import { setShopState } from "src/slices/shop";
import { useDispatch, useSelector } from "src/store";

const { Button, Alert } = require("@mui/material");

const dialogViewStates = {
  addProduct: "addProduct",
  createProduct: "createProduct",
};

const DialogAddUserProduct = ({ userAccount, open, onClose }) => {
  const { t } = useTranslation();
  const { api } = useAsyncRequest();
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);

  const [dialogViewState, setDialogViewState] = useState({
    loading: false,
    state: null,
    error: null,
    message: "",
    success: false,
    categories: [],
  });

  const { canDo } = useCanDo("shopping_cart");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const loadCategories = async () => {
    setDialogViewState({
      ...dialogViewState,
      loading: true,
      state: dialogViewStates.createProduct,
    });
    var res = await api(
      arkadu.urls.v2.account.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "category",
        action: "list",
      },
      {},
      t("Cargando categorías")
    );
    setDialogViewState({
      ...dialogViewState,
      state: dialogViewStates.createProduct,
      loading: false,
      categories: res.data.data,
    });
  };

  const handleCreateProduct = async (values) => {
    setDialogViewState({
      ...dialogViewState,
      loading: true,
      state: dialogViewStates.createProduct,
    });

    var res = await api(
      arkadu.urls.v2.account.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "product",
        action: "add",
        name: values.name,
        description: values.description,
        category_id: values.category.value,
        custom_price: values.price,
        stock: values.stock || 0,
        image: values.image,
        shipping_cost: 0,
        tax_units: values.tax_units,
        manage: !values.one_use,
      },
      {},
      t("Creando producto")
    );
    setDialogViewState({
      ...dialogViewState,
      loading: false,
      state: dialogViewStates.createProduct,
    });

    if (res.data.res === 1) {
      if (values.add_to_account) {
        await handleAddProduct({
          product_id: { value: res.data.data.id },
          quantity: 1,
          comment: values.description,
        });
      }
      dispatch(setShopState({ reloadRequest: true }));
      onClose();
      enqueueSnackbar(t("Producto creado"), {
        variant: "success",
      });
    } else {
      enqueueSnackbar(t("Error al crear producto"), { variant: "error" });
    }
  };

  const handleAddProduct = async (values) => {
    setDialogViewState({
      ...dialogViewState,
      loading: true,
    });

    var res = await api(
      arkadu.urls.v2.account.handle,
      {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
        instance: "user_product",
        action: "add",
        user_account_id: userAccount.id,
        product_id: values.product_id.value,
        quantity: values.quantity,
        comment: values.comment,
      },
      {},
      t("Agregar producto")
    );
    setDialogViewState({
      ...dialogViewState,
      loading: false,
    });

    if (res.data.res === 1) {
      dispatch(setShopState({ reloadRequest: true }));
      onClose();
      enqueueSnackbar(t("Producto agregado"), { variant: "success" });
    } else {
      enqueueSnackbar(t("Error al agregar producto"), { variant: "error" });
      setDialogViewState({
        ...dialogViewState,
        error: res.data.error,
      });
    }
  };

  if (dialogViewState.state === dialogViewStates.createProduct) {
    return (
      <PinAuthorizedView>
        {dialogViewState.loading ? (
          <LoadingScreen message={t("Procesando petición...")} />
        ) : (
          <FormBuilder
            onCancel={() => {
              setDialogViewState({
                ...dialogViewState,
                state: dialogViewStates.addProduct,
              });
            }}
            onSubmit={handleCreateProduct}
            fieldSet={[
              {
                id: "category",
                label: t("Categoría"),
                type: "select",
                options: dialogViewState.categories
                  ? dialogViewState.categories.map((c) => ({
                      value: c.id,
                      label: c.name,
                    }))
                  : [],
              },
              {
                id: "name",
                label: t("Nombre"),
                type: "text",
              },
              {
                id: "description",
                label: t("Descripción"),
                type: "textarea",
                optional: true,
              },
              {
                id: "price",
                label: t("Precio"),
                type: "number",
              },
              {
                id: "tax_units",
                label: t("Unidades Tributarias"),
                type: "number",
                initialValue: 0,
              },
              {
                id: "one_use",
                label: t("Uso único"),
                type: "boolean",
                initialValue: true,
              },
              {
                id: "add_to_account",
                label: t("Agregar a cuenta"),
                type: "boolean",
                initialValue: true,
              },
            ]}
          />
        )}
      </PinAuthorizedView>
    );
  }

  return (
    <PinAuthorizedView>
      {dialogViewState.loading && (
        <LoadingScreen message={t("Procesando petición...")} />
      )}
      {dialogViewState.error && (
        <Alert severity="error">{dialogViewState.error}</Alert>
      )}
      {!dialogViewState.loading && (
        <>
          {canDo("can_add_product") && (
            <Button
              startIcon={<AddRounded />}
              onClick={() => {
                loadCategories();
              }}
              color="info"
              variant="outlined"
            >
              {t("Crear Producto")}
            </Button>
          )}
          <FormBuilder
            onSubmit={handleAddProduct}
            onCancel={onClose}
            fieldSet={[
              {
                id: "product_id",
                label: t("Producto"),
                type: "select",
                options: currentCustomer.products
                  ? currentCustomer.products.map((product, k) => ({
                      value: product.id,
                      label: product.name,
                    }))
                  : [],
              },
              {
                id: "quantity",
                label: t("Cantidad"),
                type: "number",
              },
              {
                id: "comment",
                label: t("Nota"),
                type: "textarea",
              },
            ]}
          />
        </>
      )}
    </PinAuthorizedView>
  );
};

export default DialogAddUserProduct;
