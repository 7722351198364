import { lazy } from "react";
import SocialRespLayout from "src/views/arkadu/socialresp/SocialRespLayout";

export default [
  {
    exact: true,
    path: "/:customerName/socialresp/creator",
    layout: SocialRespLayout,
    component: lazy(() =>
      import("src/views/arkadu/socialresp/SocialRespCreator")
    ),
  },
  {
    exact: true,
    path: "/:customerName/socialresp/detail/:id",
    layout: SocialRespLayout,
    component: lazy(() =>
      import("src/views/arkadu/socialresp/SocialRespDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/socialresp",
    layout: SocialRespLayout,
    component: lazy(() =>
      import("src/views/arkadu/socialresp/SocialRespIndex")
    ),
  },
];
