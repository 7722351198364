import { lazy } from "react";
import AccountLayout from "src/views/arkadu/accounts/AcountLayout";
import BaseLayoutId from "src/views/arkadu/BaseLayoutId";

export default [
  {
    exact: true,
    path: [
      "/:customerName/accounts/teller/:tellerId/register/:registerId/cashFund/:cashFundId",
    ],
    component: lazy(() =>
      import("src/views/arkadu/accounts/parts/CashFundDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/teller/:tellerId/register/:registerId"],
    component: lazy(() =>
      import("src/views/arkadu/accounts/parts/TellerRegister")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/teller/:tellerId"],
    component: lazy(() => import("src/views/arkadu/accounts/parts/Teller")),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/adm/bankFundReport/detail/:id"],
    component: lazy(() =>
      import("src/views/arkadu/accounts/payment_account/BankReportDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/adm/cash/reports/"],
    component: lazy(() =>
      import("src/views/arkadu/accounts/cash/SCCashRegisterReports")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/adm/monitor/incomeReport/list/"],
    component: lazy(() =>
      import(
        "src/views/arkadu/accounts/parts/AddFundMonitorIncomeReportList.tsx"
      )
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/adm/monitor/incomeReport"],
    component: lazy(() =>
      import("src/views/arkadu/accounts/parts/AddFundMonitorIncomeReport")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/accounts/adm/",
      "/:customerName/accounts/adm/:tabName",
    ],
    component: lazy(() => import("src/views/arkadu/accounts/AddFundAdmin")),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/", "/:customerName/accounts/adm/:tabName"],
    component: lazy(() => import("src/views/arkadu/accounts/index")),
  },
  {
    exact: true,
    layout: AccountLayout,
    path: "/:customerName/accounts/pay/:id/",
    component: lazy(() => import("src/views/arkadu/accounts/AddFundMethods")),
  },
  {
    exact: true,
    path: [
      "/:customerName/accounts/banesco/:id/:transactionId",
      "/:customerName/accounts/banesco/:id/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/accounts/gateways/BotonBanesco.js")
    ),
  },
  {
    exact: true,
    path: "/:customerName/accounts/bdv/:id/",
    component: lazy(() =>
      import("src/views/arkadu/accounts/gateways/BdvPagos.js")
    ),
  },
  {
    exact: true,
    path  : "/:customerName/accounts/x100banco/result/:userAccountID/:id",
    component: lazy(() =>
      import("src/views/arkadu/accounts/gateways/X100BancoResult.js")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/accounts/x100banco/:id/"],
    component: lazy(() =>
      import("src/views/arkadu/accounts/gateways/X100Banco.js")
    ),
  },
  {
    exact: true,
    path: "/:customerName/accounts/addFund/:id/",
    component: lazy(() => import("src/views/arkadu/accounts/AddFund")),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: "/:customerName/accounts/userProduct/:id",
    component: lazy(() =>
      import("src/views/arkadu/accounts/user_product/UserProductDetail")
    ),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: "/:customerName/accounts/adjustment/:id/",
    component: lazy(() =>
      import("src/views/arkadu/accounts/AccountingAdjustment")
    ),
  },
  {
    exact: true,
    layout: AccountLayout,
    path: "/:customerName/accounts/funds/:id",
    component: lazy(() => import("src/views/arkadu/accounts/AccountFunds")),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: "/:customerName/accounts/transferAdmin/detail/:id",
    component: lazy(() =>
      import("src/views/arkadu/accounts/AccountTransferDetail")
    ),
  },
  {
    exact: true,
    layout: AccountLayout,
    path: "/:customerName/accounts/:id/transfer",
    component: lazy(() =>
      import("src/views/arkadu/accounts/AccountTransferAdmin")
    ),
  },
  {
    exact: true,
    path: "/:customerName/accounts/:accountId/:addFundId",
    component: lazy(() =>
      import("src/views/arkadu/accounts/parts/AddFundDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/accounts/addFund/:id/banesco/",
    component: lazy(() =>
      import("src/views/arkadu/accounts/gateways/BanescoApi")
    ),
  },

  {
    exact: true,
    path: "/:customerName/accounts/:id",
    layout: AccountLayout,
    component: lazy(() => import("src/views/arkadu/accounts/AccountDetail")),
  },
];
