import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto",
  },
}));

const NotAuthorizedView = ({ message, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Page className={classes.root} title="403 Forbidden Access">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
        >
          {t("403 Forbidden Access")}
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          {message
            ? message
            : t(
                "Carece de los permisos necesarios para acceder. " +
                  "Pongase en contacto con su administrador. Hemos enviado un " +
                  "reporte a nuestro centro de seguridad para monitorear su cuenta."
              )}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt="Forbidden"
            className={classes.image}
            src="/djstatic/images/undraw_authentication_fsn5.svg"
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/app"
            variant="outlined"
          >
            {t("back to home")}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotAuthorizedView;
