import { Block } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const ResponsiveButton = ({
  title,
  color,
  children,
  disabled,
  variant,
  onClick,
  startIcon,
  ...rest
}) => {
  const theme = useTheme();
  const c = theme.palette[color] ? theme.palette[color] : theme.palette.primary;
  // const rest = {
  //   color: color,
  //   variant: variant,
  //   onClick: onClick,
  //   startIcon: startIcon,
  // };
  var buttonStyle = {
    disabled: {
      backgroundColor: theme.palette.grey[300],
    },
    contained: {
      root: {
        backgroundColor: c.main,
      },
      backgroundColor: c.main,
      color: c.contrastText,
    },
    outlined: {
      root: {
        backgroundColor: "inherit",
        borderColor: c.main,
        border: "1px solid",
      },
      borderColor: c.main,
      border: "1px solid",
      color: c.main,
    },
    text: {
      root: {
        backgroundColor: "inherit",
        border: "none !important",
      },
      color: c.main,
    },
  };

  const hColors = ["inherit"];
  if (hColors.includes(color)) {
    buttonStyle = {};
  }
  if (disabled) {
    return (
      // <Tooltip title={title} arrow>
      <Button
        {...rest}
        color={color || "info"}
        variant={variant || "outlined"}
        style={buttonStyle.disabled}
        startIcon={<Block />}
        size="small"
        onClick={(e) => onClick(e)}
      >
        {children}
      </Button>
      // </Tooltip>
    );
  }

  return (
    // <Tooltip title={title} arrow>
    <Button
      {...rest}
      style={buttonStyle[variant]}
      variant={variant || "outlined"}
      onClick={(e) => onClick(e)}
      size="small"
    >
      {children}
    </Button>
    // </Tooltip>
  );
};
ResponsiveButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["outlined", "contained", "text"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
    "info",
    "inherit",
    "white",
  ]),
  title: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
};

ResponsiveButton.defaultProps = {
  size: "medium",
  color: "primary",
  variant: "contained",
  disabled: false,
  onClick: () => {},
  startIcon: null,
};
export default ResponsiveButton;
