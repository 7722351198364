//@ts-check
import React, { useEffect, useState } from "react";
import { getStats } from "src/slices/customers";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import NotAuthorizedView from "src/views/errors/NotAuthorizedView";

const TaxAdminContext = React.createContext();

const TaxAdminLayout = ({ children, ...rest }) => {
  const [canViewStats, setCanViewStats] = useState(false);
  const { periods, currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const { admin } = useSelector((state) => state.settings);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!periods && currentCustomer && canViewStats) {
      var postData = {
        customer_id: currentCustomer.id,
        range: "ALL",
        app_name: "taxes",
      };
      dispatch(getStats(postData));
    } else {
      //
    }
  }, [periods, currentCustomer, canViewStats]);

  useEffect(() => {
    if (currentCustomer && user) {
      setCanViewStats(
        checkPermission({
          action: "can_view_stats",
          module: "nets_customers",
          customerId: currentCustomer.id,
          user: user,
        })
      );
    }
  }, [currentCustomer, user, admin]);

  const n = 1;

  if (!canViewStats) {
    return <NotAuthorizedView />;
  }
  return (
    <TaxAdminContext.Provider value={{ n: n }}>
      {children}
    </TaxAdminContext.Provider>
  );
};

export { TaxAdminContext };
export default TaxAdminLayout;
