import { server } from "src/coreConfig";
import { setProcedureState } from "src/slices/procedures";
import { useDispatch, useSelector } from "src/store";

const corporateUrls = {
    json: {
        procedures: `/djstatic/:customerName/procedures.json`,
    },
  appointment: {
    search: `${server}/api/v2/corporate/appointment/search/`,
    update: `${server}/api/v2/corporate/appointment/update/`,
    validate: `${server}/api/v2/corporate/appointment/validate/`,
    getAvailable: `${server}/api/v2/corporate/appointment/getAvailable/`,
    create: `${server}/api/v2/corporate/appointment/create/`,
  },
  department: {
    add: `${server}/api/v2/corporate/department/add/`,
    delete: `${server}/api/v2/corporate/department/delete/`,
    detail: `${server}/api/v2/corporate/department/detail/`,
    list: `${server}/api/v2/corporate/department/`,
    update: `${server}/api/v2/corporate/department/update/`,
  },
  holiday: {
    add: `${server}/api/v2/corporate/holiday/add/`,
    delete: `${server}/api/v2/corporate/holiday/delete/`,
    detail: `${server}/api/v2/corporate/holiday/detail/`,
    list: `${server}/api/v2/corporate/holiday/`,
    update: `${server}/api/v2/corporate/holiday/update/`,
  },
  office: {
    add: `${server}/api/v2/corporate/office/add/`,
    delete: `${server}/api/v2/corporate/office/delete/`,
    detail: `${server}/api/v2/corporate/office/detail/`,
    list: `${server}/api/v2/corporate/office/`,
    update: `${server}/api/v2/corporate/office/update/`,
  },
  procedure: `${server}/api/v2/corporate/procedure/`,
  procedureAdmin: {
    add: `${server}/api/v2/corporate/procedure/add/`,
    addRequirement: `${server}/api/v2/corporate/procedure/addRequirement/`,
    availableRequirements: `${server}/api/v2/corporate/procedure/availableRequirements/`,
    delete: `${server}/api/v2/corporate/procedure/delete/`,
    deleteRequirement: `${server}/api/v2/corporate/procedure/deleteRequirement/`,
    detail: `${server}/api/v2/corporate/procedure/detail/`,
    list: `${server}/api/v2/corporate/procedure/`,
    update: `${server}/api/v2/corporate/procedure/update/`,
    updateRequirement: `${server}/api/v2/corporate/procedure/updateRequirement/`,
  },
  requirement: {
    add: `${server}/api/v2/corporate/requirement/add/`,
    delete: `${server}/api/v2/corporate/requirement/delete/`,
    list: `${server}/api/v2/corporate/requirement/`,
    update: `${server}/api/v2/corporate/requirement/update/`,
  },
  schedule: {
    add: `${server}/api/v2/corporate/schedule/add/`,
    addDay: `${server}/api/v2/corporate/schedule/addDay/`,
    delete: `${server}/api/v2/corporate/schedule/delete/`,
    deleteDay: `${server}/api/v2/corporate/schedule/deleteDay/`,
    detail: `${server}/api/v2/corporate/schedule/detail/`,
    list: `${server}/api/v2/corporate/schedule/`,
    update: `${server}/api/v2/corporate/schedule/update/`,
    updateDay: `${server}/api/v2/corporate/schedule/updateDay/`,
  },
  staff: {
    list: `${server}/api/v2/corporate/staff/`,
    logs: `${server}/api/v2/corporate/staff/logs/`,
  },
  state: {
    add: `${server}/api/v2/corporate/state/add/`,
    delete: `${server}/api/v2/corporate/state/delete/`,
    detail: `${server}/api/v2/corporate/state/detail/`,
    list: `${server}/api/v2/corporate/state/`,
    update: `${server}/api/v2/corporate/state/update/`,
  },
  user_appointment: `${server}/api/v2/corporate/procedure/userAppointment/`,
  user_procedure: `${server}/api/v2/corporate/procedure/userProcedure/`,
  userProcedure: {
    add: `${server}/api/v2/corporate/userProcedure/add/`,
    adminList: `${server}/api/v2/corporate/userProcedure/admin/`,
    changeState: `${server}/api/v2/corporate/userProcedure/changeState/`,
    delete: `${server}/api/v2/corporate/userProcedure/delete/`,
    detail: `${server}/api/v2/corporate/userProcedure/detail/`,
    list: `${server}/api/v2/corporate/userProcedure/`,
    update: `${server}/api/v2/corporate/userProcedure/update/`,
    updatePayment: `${server}/api/v2/corporate/userProcedure/updatePayment/`,
    updateRequirement: `${server}/api/v2/corporate/userProcedure/updateRequirement/`,
    updateRequirementStatus: `${server}/api/v2/corporate/userProcedure/updateRequirementStatus/`,
  },
};
const useCorporate = () => {
    const {currentCustomer} = useSelector((state) => state.customers);
    const dispatch = useDispatch();

    const loadProceduresList = async () => {
        const response = await fetch(corporateUrls.json.procedures.replace(":customerName", currentCustomer.shortname));
        const data = await response.json();
        dispatch(setProcedureState({
            procedures: data.procedures,
            departments: data.departments,
            procedureStates: data.states,
            proceduresLoaded: true
        }));
        localStorage.setItem(`${currentCustomer.id}_procedures_v`, data.version || 1);

        return data;
    }
  return {
    loadProceduresList,
  };
};

export default useCorporate;
