//@ts-check
import { CheckRounded, GppBadRounded, LockRounded } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import LoadingScreen from "src/components/LoadingScreen";
import SecurePinField from "src/components/SecurePin";
import arkadu from "src/coreConfig";
import { useDispatch, useSelector } from "src/store";
import axios from "src/utils/axios";
import * as Yup from "yup";

const SecurityPinInline = ({ onAuthorized, userMode }) => {
  const { t } = useTranslation();
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const initialized = useRef(null);
  const history = useHistory();
  const [isSearching, setIsSearching] = useState(false);
  const handleSearch = (searchText) => {};
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [authorized, setAuthorized] = useState(false);
  const loadData = async () => {
    var res = await axios.post(arkadu.urls.v2);
    if (res.data.res === 1) {
      setData(res.data.data);
    } else {
      enqueueSnackbar(res.data.message || "error", { variant: "error" });
    }
  };

  useEffect(() => {
    if (initialized.current === undefined) {
      initialized.current = true;
    }
  }, []);
  if (!currentCustomer) {
    return <LoadingScreen message={t("Cargando componentes")} />;
  }

  if (!adminMode && !userMode) {
    return (
      <Alert severity="error" icon={<GppBadRounded />}>
        <Typography variant="h4">{t("Acceso denegado")}</Typography>
      </Alert>
    );
  }

  if (authorized) {
    return (
      <Alert severity="success" icon={<CheckRounded />}>
        {t("Autorizado")}
      </Alert>
    );
  }
  return (
    <Formik
      initialValues={{
        pin: "",
      }}
      validationSchema={Yup.object().shape({
        pin: Yup.string()
          .min(4, t("Debe ser cuatro dígitos"))
          .max(4, t("Debe ser cuatro dígitos"))
          .required(t("Debe introducir su PIN de seguridad")),
      })}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);

        try {
          var res = await axios.post(arkadu.urls.v2.user.pinAuthorization, {
            pin: values.pin,
          });
          if (res.data.res === 1) {
            setAuthorized(true);
            onAuthorized();
            enqueueSnackbar(t("Autorizado"), { variant: "success" });
          } else {
            setFieldError("pin", res.data.message);
            enqueueSnackbar(res.data.message, { variant: "error" });
          }
        } catch (e) {
          console.log(e);
          enqueueSnackbar("error", { variant: "error" });
        }
      }}
    >
      {({
        values,
        setFieldValue,
        handleChange,
        setTouched,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
      }) => (
        <Grid sx={{ padding: "10px 24px !important" }}>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <div>
              <Typography sx={{ display: "flex" }}>
                {<LockRounded />} {t("Pin requerido")}
              </Typography>
              <div>
                <SecurePinField
                  value={values.pin}
                  onComplete={async (v) => {
                    setFieldValue("pin", v);

                    await setTimeout(() => {}, 300);
                    setTouched({ pin: true });
                   
                    handleSubmit();
                  }}
                  onChange={(v) => {
                    //  setFieldValue("pin", v)
                  }}
                />
              </div>
              <FormHelperText error={Boolean(errors.pin && touched.pin)}>
                {errors.pin && touched.pin
                  ? errors.pin
                  : t("Introduzca su PIN de seguridad")}
              </FormHelperText>

              <Button
                size="small"
                target="_blank"
                variant="outlined"
                color="info"
                href={`/app/account/security/pinChange/`}
                component={Link}
              >
                {t("Establecer pin")}
              </Button>
            </div>
          )}
        </Grid>
      )}
    </Formik>
  );
};

SecurityPinInline.propTypes = {
  onAuthorized: PropTypes.func,
  userMode: PropTypes.bool,
};

export default SecurityPinInline;
