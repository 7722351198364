import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/urbin/request/detail/:requestId"],
    component: lazy(() => import("src/views/arkadu/urbin/UrbinRequestDetail")),
  },
  {
    exact: true,
    path: ["/:customerName/urbin/:tabName", "/:customerName/urbin/"],
    component: lazy(() => import("src/views/arkadu/urbin/UrbinIndex")),
  },
];
