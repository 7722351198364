import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography, useTheme, DialogTitle as MuiDialogTitle } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CustomDialogTitle = (props) => {
  const { children, classes, onClose, avatar, color, ...other } = props;
  const thisClasses = useStyles();

  const theme = useTheme();
  const c = theme.palette[color] ? theme.palette[color] : theme.palette.primary;
  const titleStyle = makeStyles((t) => ({
    titleColored: {
      backgroundColor: c.main,
      color: c.contrastText,
    },
    contrastText: {
      color: c.contrastText,
    },
    avatar: {
      marginRight: t.spacing(1),
    },
  }));
  const Avatar = avatar;

  const titleClasses = titleStyle();

  return (
    <MuiDialogTitle
      // disableTypography
      className={clsx(thisClasses, titleClasses.titleColored)}
      {...other}
    >
      <Grid container direction="row">
        <Grid item>
          {" "}
          {avatar && (
            <Avatar
              className={clsx(titleClasses.titleColored, titleClasses.avatar)}
            />
          )}
        </Grid>
        <Grid item>
          <Typography variant="h5">{children}</Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={clsx(
                thisClasses.closeButton,
                titleClasses.contrastText
              )}
              onClick={onClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
};

export default CustomDialogTitle;
