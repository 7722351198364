import { lazy } from "react";
import BaseLayoutId from "src/views/arkadu/BaseLayoutId";

export default [
  {
    exact: true,
    layout: BaseLayoutId,
    path: ["/:customerName/stamp/order/:id/"],
    component: lazy(() => import("src/views/arkadu/stamp/StampOrderDetail")),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: ["/:customerName/stamp/edit/:id/"],
    component: lazy(() => import("src/views/arkadu/stamp/StampDetailAdmin")),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: ["/:customerName/stamp/detail/:id/"],
    component: lazy(() => import("src/views/arkadu/stamp/StampDetail")),
  },
  {
    exact: true,
    path: ["/:customerName/stamp/:tabName/", "/:customerName/stamp/"],
    component: lazy(() => import("src/views/arkadu/stamp/StampIndex")),
  },
];
