import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/notify/email/new/"],
    component: lazy(() =>
      import("src/views/arkadu/notify/admin/NotifyCustomEmailForm")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/notify/"],
    component: lazy(() =>
      import("src/views/arkadu/notify/admin/NotifyAdminIndex")
    ),
  },
];
