import { SearchRounded } from "@mui/icons-material";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const TextInputSearch = ({
  onSearch,
  onCancelSearch,
  min,
  isSearching,
  label,
  ...rest
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchHelpText, setSearchHelpText] = useState("");
  const initialized = useRef(null);
  const [touched, setTouched] = useState(false);
  const handleChange = (e) => {
    if (e.target.value.length > 0 && !touched) {
      setTouched(true);
    }
    setSearchText(e.target.value);
  };

  const handleSearch = () => {
    if (searchText.length >= min) {
      onSearch(searchText);
      setSearchHelpText("");
    } else {
      setSearchHelpText(t(`por favor introduzca al menos ${min} caracteres`));
    }
  };

  useEffect(() => {
    if (touched && (!searchText || searchText.length === 0)) {
      if (onCancelSearch) {
        onCancelSearch();
      }
    }
  }, [searchText]);

  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);
  return (
    <TextField
      size="small"
      /***********************************
       */
      name="search"
      variant="outlined"
      fullWidth
      disabled={Boolean(isSearching)}
      InputProps={{
        // startAdornment: searchText && onCancelSearch && (
        //   <IconButton
        //     onClick={() => {
        //       setSearchText("");

        //       onCancelSearch();
        //     }}
        //   >
        //     <CancelRounded />
        //   </IconButton>
        // ),
        endAdornment: isSearching ? (
          <CircularProgress />
        ) : (
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleSearch()}
              color="primary"
              disabled={Boolean(!searchText || searchText.length < min)}
              size="large"
            >
              <SearchRounded />
            </IconButton>{" "}
          </InputAdornment>
        ),
      }}
      helperText={searchHelpText}
      label={label || t("Buscar")}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSearch();
        }
      }}
    />
  );
};

TextInputSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  min: PropTypes.number,
  cancelSearch: PropTypes.func,
  onCancelSearch: PropTypes.func,
  label: PropTypes.string,
  isSearching: PropTypes.bool,
};

TextInputSearch.defaultProps = {
  min: 3,
  isSearching: false,
  onSearch: () => {},
  onCancelSearch: () => {},
  cancelSearch: () => {},
  label: "Buscar",
};
export default TextInputSearch;
