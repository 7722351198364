import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import LottieAnimation from "src/components/LottieAnimation";
import Page from "src/components/Page";
import animationData from "src/lotties/arkadu_not_found.json";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto",
  },
}));

const NotFoundView = ({ message, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Page className={classes.root} title="404: Not found">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? "h4" : "h1"}
          color="textPrimary"
        >
          {t("404: La página que buscas no existe.")}
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          {message
            ? message
            : t(
                "You either tried some shady route or you" +
                  "came here by mistake. Whichever it is, try using the navigation."
              )}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <LottieAnimation animationData={animationData} size={"60%;"} />
          {/* <img
            alt="Under development"
            className={classes.image}
            src="https://arkadu.com/static/images/undraw_page_not_found_su7k.svg"
          /> */}
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/app"
            variant="outlined"
          >
            {t("back to home")}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
