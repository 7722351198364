import { lazy } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ModuleView from "src/views/arkadu/module/ModuleView";
import UserLayout from "src/views/arkadu/user/UserLayout";

export default [
  {
    path: "/app/account/",
    layout: UserLayout,
    routes: [
      {
        exact: true,
        path: ["/app/account/registration/"],
        component: lazy(() =>
          import("src/views/arkadu/user/SimpleRegistrationForm")
        ),
      },
      {
        exact: true,
        path: "/app/account/companies/detail/:id",
        component: lazy(() =>
          import("src/views/arkadu/user/company/CompanyDetail")
        ),
      },
      {
        exact: true,
        path: "/app/account/companies/new",
        component: lazy(() =>
          import("src/views/account/AccountView/Steppers/NewCompanySteps")
        ),
      },

      {
        exact: true,
        path: "/app/account/addresses/new",
        component: lazy(() =>
          import("src/views/account/AccountView/NewAddress")
        ),
      },
      {
        exact: true,
        path: "/app/account/identities/new",
        component: lazy(() =>
          import("src/views/account/AccountView/NewIdentity")
        ),
      },
      {
        exact: true,
        path: "/app/account/identity/verify/:IdentityId",
        component: lazy(() =>
          import("src/views/arkadu/user/Identity/VerificationRequest")
        ),
      },
      // {
      //   exact: true,
      //   path: '/app/account/vehicles/new',
      //   component: lazy(() =>
      //     import('src/views/account/AccountView/NewVehicle')
      //   )
      // },
      {
        exact: true,
        path: ["/app/account/security/pinChange/"],
        component: lazy(() =>
          import("src/views/account/AccountView/Security/UserPinChange")
        ),
      },
      {
        exact: true,
        path: [
          "/app/account/:tabName/detail/:vehicleId",
          "/app/account/:tabName/:actionName",
          "/app/account/:tabName",
          "/app/account",
        ],
        component: lazy(() => import("src/views/account/AccountView")),
      },
    ],
  },
  {
    exact: true,
    path: ["/app/notify/:tabName", "/app/notify/"],
    component: lazy(() => import("src/views/arkadu/notify/index")),
  },
  // {
  //   exact: true,
  //   path: "/app/newOrganization/",
  //   component: lazy(() =>
  //     import("src/views/arkadu/customer/forms/NewCustomerForm")
  //   ),
  // },
  {
    exact: true,
    path: "/app/organizations/",
    component: lazy(() => import("src/views/arkadu/customer/OwnedCustomers")),
  },
  {
    exact: true,
    path: "/app",
    component: () => <ModuleView />,
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
