import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/audit/detail/:id"],
    component: lazy(() => import("src/views/arkadu/audit/AuditDetail")),
  },
  {
    exact: true,
    path: ["/:customerName/audit/summon/:id"],
    component: lazy(() => import("src/views/arkadu/audit/AuditSummonDetail")),
  },
  {
    exact: true,
    path: ["/:customerName/audit/:tabName", "/:customerName/audit/"],
    component: lazy(() => import("src/views/arkadu/audit/AuditIndex")),
  },
];
