import { useSnackbar } from "notistack";
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import arkadu from "src/coreConfig";
import useWebSocket from "src/hooks/useWebSocket";
import { setGlobalItem } from "src/slices/global";
import { checkPermission } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";

const GlobalContext = createContext();

const GlobalLayout = ({ children, ...rest }) => {
  const { t } = useTranslation();
  const { sendWs, lastMessage, wsMessages } = useWebSocket(
    arkadu.urls.webSocket.global
  );
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const { adminMode } = useSelector((state) => state.settings);

  const { currentCustomer } = useSelector((state) => state.customers);
  const createItems = (data) => {
    sendWs(data);
  };
  const fetchItems = (data) => {
    sendWs(data);
  };

  const { taxUnits, globalPermissions } = useSelector((state) => state.global);

  useEffect(() => {
    if (!taxUnits && currentCustomer) {
      fetchItems(
        JSON.stringify({
          type: "global_request",
          message: {
            type: "get_globals",
            customer_id: currentCustomer.id,
          },
        })
      );
    }
  }, [taxUnits, currentCustomer]);

  useEffect(() => {
    if (adminMode && currentCustomer && user) {
      dispatch(
        setGlobalItem({
          name: "globalPermissions",
          data: {
            addTaxUnit: checkPermission({
              user: user,
              action: "can_add_taxunit",
              module: "nets_global",
              customerId: currentCustomer.id,
            }),
            addCurrency: checkPermission({
              user: user,
              action: "can_add_currency",
              module: "nets_global",
              customerId: currentCustomer.id,
            }),
          },
        })
      );
    }
  }, [adminMode, currentCustomer, user]);

  useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.type) {
        case "global_error":
          enqueueSnackbar(lastMessage.message, { variant: "error" });
          break;
        case "global_items":
          dispatch(
            setGlobalItem({
              name: "taxUnits",
              data: lastMessage.data.tax_units,
            })
          );
          dispatch(
            setGlobalItem({
              name: "currencies",
              data: lastMessage.data.currencies,
            })
          );
          break;
        default:
          break;
      }
    }
  }, [lastMessage]);
  return (
    <GlobalContext.Provider value={{ fetchItems, createItems }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext };

export default GlobalLayout;
