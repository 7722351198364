import React, { useEffect } from "react";
import LottieAnimation from "src/components/LottieAnimation";
import useUrbinRequest from "src/hooks/useUrbinRequest";
import animationData from "src/lotties/arkadu_city.json";
import { useSelector } from "src/store";

const UrbinLayout = ({ children }) => {
  const { urbanProducts } = useSelector((state) => state.urbin);

  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);

  const { loadProducts } = useUrbinRequest(currentCustomer, adminMode);

  useEffect(() => {
    console.log("UrbinLayout useEffect", urbanProducts, currentCustomer);
    if (!urbanProducts && currentCustomer) {
      loadProducts();
    }
  }, [urbanProducts, currentCustomer]);
  if (!urbanProducts) {
    return <LottieAnimation animationData={animationData} size={"400px;"} />;
  }
  return <>{children}</>;
};

export default UrbinLayout;
