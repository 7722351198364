import { lazy } from "react";

export default [
  {
    exact: true,
    path: ["/:customerName/environment/penalties/detail/:id"],
    component: lazy(() =>
      import("src/views/arkadu/environment/penalties/EnviromentPenaltyDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/environment/request/detail/:requestId"],
    component: lazy(() =>
      import("src/views/arkadu/environment/EnvironmentRequestDetail")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/environment/:tabName",
      "/:customerName/environment/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/environment/EnvironmentIndex")
    ),
  },
];
