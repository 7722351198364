import { useSnackbar } from "notistack";
import React, { createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import arkadu from "src/coreConfig";
import useWebSocket from "src/hooks/useWebSocket";
import { setUserItem } from "src/slices/users";
import { useDispatch } from "src/store";

const UserContext = createContext();

const UserLayout = ({ children, ...rest }) => {
  const { ws, sendWs, wsOnline, lastMessage } = useWebSocket(
    arkadu.urls.webSocket.user
  );
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const sendUserWs = (data) => {
    sendWs(
      JSON.stringify({
        type: "user_request",
        message: data,
      })
    );
  };

  useEffect(() => {
    if (lastMessage && lastMessage.type) {
      switch (lastMessage.type) {
        case "user_error":
          enqueueSnackbar(lastMessage.message, { variant: "error" });
          break;
        case "user_updated":
          enqueueSnackbar(t("Datos actualizados"), { variant: "success" });
          dispatch(setUserItem({ user: lastMessage.data }));
          break;
        default:
          break;
      }
    }
  }, [lastMessage]);
  return (
    <UserContext.Provider value={{ sendUserWs }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };

export default UserLayout;
