import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import LoadingScreen from "src/components/LoadingScreen";
import { setProcedureState } from "src/slices/procedures";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const CorporateLayout = ({ children }) => {
  const { proceduresLoaded } = useSelector((state) => state.procedures);
  const dispatch = useDispatch();
  const initialized = useRef(null);
  const { adminMode } = useSelector((state) => state.settings);
  const { currentCustomer } = useSelector((state) => state.customers);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loadData = async () => {
    // /djstatic/losguayos/procedures.json?v=1
    // we will use localStorate to save this data and refresh it every diff day
    // we need send version from previous data to get new data if not exist v = 1
    localStorage.removeItem(`${currentCustomer.id}_procedures`);
    var version = 1;
    var path = `/djstatic/${currentCustomer.shortname}/procedures.json`;

    var currentData = await localStorage.getItem(
      `${currentCustomer.id}_procedures_v`
    );
    console.log(currentData, "currentData");
    if (currentData !== null) {
      version = parseInt(currentData) + 1;
    }
    path = `${path}?v=${version}`;
    axiosInstance
      .get(path)
      .then((response) => {
        // response is from nginx json file
        // check if response.data is text or json
        var data = response.data;
        if (typeof response.data === "string") {
          try {
            data = JSON.parse(response.data);
          } catch (e) {
            console.log(e, "error parsing json");
          }
        }
        console.log(data.states, "data.states");
        console.log(data, "data");
        dispatch(
          setProcedureState({
            procedures: data.procedures,
            departments: data.departments,
            procedureStates: data.states,
            proceduresLoaded: true,
          })
        );
        localStorage.setItem(
          `${currentCustomer.id}_procedures_v`,
          data.version || 1
        );
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t("something was wrong"), { variant: "error" });
      });
    // axiosInstance
    //   .post(arkadu.urls.v2.corporate.procedure, {
    //     action: "",
    //     mode: adminMode ? "admin" : "user",
    //     customer_id: currentCustomer.id,
    //   })
    //   .then((response) => {
    //     if (response.data.res === 1) {
    //       dispatch(
    //         setProcedureState({
    //           procedures: response.data.data.procedures,
    //           departments: response.data.data.departments,
    //           procedureStates: response.data.data.states,
    //           proceduresLoaded: true,
    //         })
    //       );
    //       // dispatch(
    //       //   updateFilters({
    //       //     state: {
    //       //       value: response.data.data.states[0].id,
    //       //       label: response.data.data.states[0].name,
    //       //     },
    //       //   })
    //       // );
    //     } else {
    //       enqueueSnackbar(response.data.message, { variant: "error" });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     enqueueSnackbar(t("something was wrong"), { variant: "error" });
    //   });
  };
  useEffect(() => {
    if (initialized.current === null) {
      initialized.current = true;
    }
  }, []);

  useEffect(() => {
    if (!proceduresLoaded && currentCustomer) {
      loadData();
    }
  }, [proceduresLoaded, currentCustomer]);

  if (!proceduresLoaded) {
    return <LoadingScreen />;
  }
  return <div>{children}</div>;
};
export default CorporateLayout;
