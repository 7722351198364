import { Alert } from "@mui/material";
import ProTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { setAuthorized } from "src/slices/users";
import { useDispatch, useSelector } from "src/store";
import SecurityPinInline from "src/views/forms/user/SecurityPinInline";

const env = process.env.NODE_ENV;

const PinAuthorizedView = ({ children, ignoreEnvironment }) => {
  const { user, authorized } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!authorized && (env !== "development" || ignoreEnvironment)) {
    return (
      <SecurityPinInline onAuthorized={() => dispatch(setAuthorized(true))} />
    );
  }

  return (
    <>
      {env === "development" && (
        <Alert severity="error">
          {t("Desarrollo: No se requiere pin de seguridad")} <br />
          {t(
            "Para activar el pin de seguridad, cambie la variable de entorno NODE_ENV a 'production'"
          )}
        </Alert>
      )}
      {children}
    </>
  );
};

PinAuthorizedView.propTypes = {
  ignoreEnvironment: ProTypes.bool,
  children: ProTypes.node,
};

PinAuthorizedView.defaultProps = {
  ignoreEnvironment: false,
};
export default PinAuthorizedView;
