import { Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import GroupButtonMenu from "src/components/custom/GroupButtonMenu";
import Page from "src/components/Page";
import { useDispatch, useSelector } from "src/store";
import BaseHeader from "./BaseHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& .TabPanel": {
      padding: 0,
      marginTop: "0px !important",
    },
    // divs with role="tabpanel"
    "& div[role='tabpanel']": {
      padding: 0,
      marginTop: "0px !important",
    },
    "& div[role='tabpanel'] .MuiTab-root": {
      fontSize: "0.65rem",
      lineHeight: "1 !important",
      maxHeight: "65px !important",
      minHeight: "65px !important",
      fontWeight: "400",
      padding: "8px 10px",
    },
    "& div[role='tabpanel'] .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
}));

const BasePage = ({
  children,
  title,
  subTitle,
  actionsMenu,
  mainAction,
  subHeader,
  searchProps,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const { customers, currentCustomer } = useSelector(
    (state) => state.customers
  );
  const pageRef = useRef(null);

  return (
    <PerfectScrollbar>
      <Page className={classes.root} title={`${title}`} ref={pageRef}>
        <div className={classes.mainContent}>
          <Container maxWidth={false}>
            <BaseHeader
              title={title}
              searchProps={searchProps}
              subTitle={subTitle}
              actionsMenu={actionsMenu}
              mainAction={mainAction}
              currentCustomer={currentCustomer}
              subHeader={subHeader}
            />
            <Box
              mt={3}
              sx={{
                marginLeft: { xs: "-14px", md: "inherit" },
                marginRight: { xs: "-14px", md: "inherit" },
                marginTop: { xs: "1rem !important" },
              }}
            >
              {children}
            </Box>
          </Container>
        </div>
      </Page>
    </PerfectScrollbar>
  );
};

const actionMenu = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf([
    "default",
    "inherit",
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

BasePage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actionsMenu: GroupButtonMenu.propTypes.items,
  mainAction: PropTypes.node,
  subHeader: PropTypes.string,
  searchProps: PropTypes.object,
};
BasePage.defaultProps = {
  mainAction: null,
  actionsMenu: null,
  title: "",
  subTitle: "",
  subHeader: "",
  searchProps: null,
  children: null,
};
export default BasePage;
