import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

const LottieAnimation = ({ animationData, size, message }) => {
  // prevent size bigger than 40%
  if (size && size.includes("%")) {
    size = parseInt(size.replace("%", ""));
    if (size > 40) {
      size = "40%";
    } else {
      size = size + "%";
    }
  }
  // if size is not a percentage, it will be a fixed size, so we 
  // need to check the window size and set the size accordingly
  if (size && !size.includes("%")) {
    if (window.innerWidth < 600) {
      size = "200px";
    } else if (window.innerWidth < 960) {
      size = "300px";
    } else {
      size = "400px";
    }
  }
  
  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        flexDirection: "column !important",
        display: "flex",
        alignItems: "center",
        paddingBottom: "20px;",
        height: "80vh",
      }}
    >
      <Grid
        sx={{
          width: size || "200px;",
        }}
      >
        <Lottie
          options={{
            animationData: animationData,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRadio: "xMidYMid slice",
            },
          }}
        />
      </Grid>

      {message && (
        <Grid item>
          <Typography sx={{ fontStyle: "italic" }}>{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
LottieAnimation.propTypes = {
  animationData: PropTypes.object.isRequired,
  size: PropTypes.string,
  message: PropTypes.string,
};
export default LottieAnimation;
