import { colors, createTheme, responsiveFontSizes } from "@mui/material";
import _ from "lodash";
import { THEMES } from "src/constants";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const remoteTheme = window["theme"];
// const arkaduTheme = window.arkadu_t;

const newGray = "#848694";
const arkaduBlue = "#0F4C81";
const arkaduRed = "#FF1744";
const arkaduRedBg = "linear-gradient(180deg, #FF1744 30%, #E6294A 90%)";
const arkaduGray =
  "linear-gradient(180deg, rgba(37,37,37,1) 0%, rgba(26,34,32,1) 40%, rgba(17,18,18,1) 100%)";

const arkaduRedDark = "#E6294A";
const arkaduGrayPallete = {
  success: "#28a745",
  danger: "#E30613",
  warning: "#F6A000",
  info: "#018198",
  primary: {
    main: "#1F2B33",
    dark: "#172026",
    light: "#FFD500",
    secondary: "#018198",
  },
  secondary: {
    main: "#018198",
    dark: "#018198",
    light: "#FEDA00",
    dark2: "#DB8905",
  },
  primaryText: "#1F2B33",
  secondaryText: "#018198",
  background: {
    default: "#1F2B33",
    secondary: "#FEDA00",
    gray: "#FFFFFF",
  },
};

// COLOR BANESCO "001371" "007953" cuenta origen

const arkaduBlueLight = "#4c77b1";
const textDark = "#2d2d2d";
const arkaduBlueDark = "#002554";
const secondaryColor = "#1AFF85";
// const secondaryColor = '#009999';
const secondaryColorLight = "#00b28e";
const secondaryColorDark = "#64ffdf";
const newGreen = "#114430";
const unicornColor = "#64ffdf";

const barAdmin = secondaryColor;

const p = arkaduGrayPallete;

const baseOptions = {
  direction: "ltr",
  typography,
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiTabs: {
      flexContainer: {
        background: "white", //p.background.gray,

        color: `${p.primary.main} !important`,
        borderBottom: "1px #ccc Solid",
      },
    },
    PrivateTabIndicator: {
      root: {
        backgroundColor: `${p.secondary.main} !important`,
        height: "4px !important",
      },
    },
    MuiGrid: {
      root: {
        "& .rdw-editor-wrapper": {
          fontFamily: "Roboto !important",
        },
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "inherit !important",
      },
      textColorPrimary: {
        color: `${p.secondary.main} !important`,
        "& Mui-selected": {
          color: `${p.secondary.main} !important`,
        },
      },
    },
    MuiDrawer: {
      paper: {
        background: `${p.background.gray} !important`,
      },
    },

    MuiMenuItem: {
      root: {
        color: "pink !important",
        padding: "100px !important",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: p.background.gray,
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: "#FFF !important",
        color: `${p.secondary.main} !important`,
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: `rgb(232 232 232);`,
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: "yellow !important",
        },
      },
      root: {
        backgroundColor: "pink !important",
      },
    },
  },
  components: {
    // MuiTable: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: "black !important",
    //     },
    //   },
    // },
    MuiTable: {
      styleOverrides: {
        root: {
          width: "100% !important",
          margin: "auto",
          border: "none !important",
          borderRadius: "0px",
          boxShadow: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0px;",
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: "1px #ccc Solid",
        },
      },
    },
    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(232, 232, 232, 0.5) !important",
        },
      },
    },
  },
  // components: {
  //   MuiTable: {
  //     styleOverrides: {
  //       root: {
  //         width: "40% !important",
  //         margin: "0 auto",
  //         backgroundColor: "pink",
  //         border: "10px Solid red !important",
  //       },
  //     },
  //     root: {
  //       width: "100% !important",
  //       margin: "0 auto",
  //       backgroundColor: "pink",
  //     },
  //   },
  //   MUIDataTable: {
  //     styleOverrides: {
  //       root: {
  //         width: "40% !important",
  //         margin: "0 auto",
  //         backgroundColor: "pink",
  //         border: "none !important",
  //       },
  //     },
  //     // root: {
  //     //   width: "100% !important",
  //     //   margin: "0 auto",
  //     //   backgroundColor: "pink",
  //     // },
  //   },
  //   MUIDataTableFilter: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: `rgb(232 232 232) !important`,
  //       },
  //     },
  //   },
  // },
};

// components: {
//   // MuiTable: {
//   //   styleOverrides: {
//   //     root: {
//   //       width: "98% !important",
//   //       margin: "0 auto",
//   //     },
//   //   },
//   // },
//   MUIDataTableFilter: {
//     styleOverrides: {
//       root: {
//         backgroundColor: `rgb(232 232 232) !important`,
//       },
//     },
//   },
// },
const themesOptions = [
  {
    name: THEMES.LIGHT,
    palette: {
      mode: "light",
      action: {
        active: p.primary.light,
      },
      background: {
        ...p.background,
        dark: "#FFF", //"#f4f6f8",
        paper: colors.common.white,
      },
      primary: p.primary,
      secondary: p.secondary,
      danger: {
        main: p.danger,
      },
      text: {
        primary: p.primaryText,
        secondary: p.secondaryText,
      },
      success: {
        main: p.success,
      },
      adminBar: {
        main: p.secondary,
      },
    },
    shape: {
      borderRadius: 12,
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#8a85ff",
      },

      success: {
        main: p.success,
      },
      danger: {
        main: p.danger,
      },
      secondary: {
        main: "#8a85ff",
      },
      text: {
        primary: "rgba(255, 255, 255, 0.87)",
        secondary: "rgba(255, 255, 255, 0.6)",
        disabled: "rgba(255, 255, 255, 0.38)",
      },
      adminBar: {
        main: p.secondary,
      },
    },
    shape: {
      borderRadius: 12,
    },
    shadows: strongShadows,
  },
  // {
  //   name: THEMES.UNICORN,
  //   palette: {
  //     mode: "dark",
  //     action: {
  //       active: "rgba(255, 255, 255, 0.54)",
  //       hover: "rgba(255, 255, 255, 0.04)",
  //       selected: "rgba(255, 255, 255, 0.08)",
  //       disabled: "rgba(255, 255, 255, 0.26)",
  //       disabledBackground: "rgba(255, 255, 255, 0.12)",
  //       focus: "rgba(255, 255, 255, 0.12)",
  //     },
  //     background: {
  //       default: "#2a2d3d",
  //       dark: "#222431",
  //       paper: "#2a2d3d",
  //     },
  //     primary: {
  //       main: unicornColor,
  //     },
  //     danger: {
  //       main: p.danger,
  //     },
  //     success: {
  //       main: p.success,
  //     },
  //     secondary: {
  //       main: unicornColor,
  //     },
  //     text: {
  //       primary: "#f6f5f8 ",
  //       secondary: "#9699a4",
  //     },
  //     adminBar: {
  //       main: p.secondary,
  //     },
  //   },
  //   shape: {
  //     borderRadius: 12,
  //   },
  //   shadows: strongShadows,
  // },
];

export const buildTheme = (config = {}, customerTheme = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    themeOptions = themesOptions.find((theme) => theme.name === "LIGHT");
    [themeOptions] = themesOptions;
  }

  if (customerTheme.primary) {
    themeOptions = _.merge(themeOptions, { palette: customerTheme });
  }
  themeOptions = _.merge(themeOptions, baseOptions);
  themeOptions = _.merge(themeOptions, remoteTheme);

  var themeConfig = {
    // ...baseOptions,
    ...themeOptions,

    direction: config.direction,
  };
  let theme = createTheme(themeConfig);

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
