import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setSocialResponsibilityState } from "src/slices/social_responsibility";
import { useDispatch, useSelector } from "src/store";
import axiosInstance from "src/utils/axios";

const urls = {
  socialresp: {
    list: `${server}/api/v2/socialresp/list/`,
    add: `${server}/api/v2/socialresp/add/`,
    update: `${server}/api/v2/socialresp/update/`,
    delete: `${server}/api/v2/socialresp/delete/`,
    categories: `${server}/api/v2/socialresp/categories/`,
    detail: `${server}/api/v2/socialresp/detail/`,
    exonerate: `${server}/api/v2/socialresp/exonerate/`,
  },
};

const useSocialResp = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentCustomer } = useSelector((state) => state.customers);
  const { adminMode } = useSelector((state) => state.settings);
  const { socialRespCategories, socialRespList } = useSelector(
    (state) => state.socialResponsibility
  );
  const [socialRespState, setSocialRespState] = useState({
    socialRespList: [],
    socialRespLoading: false,
    socialRespError: null,
    socialRespCategories: [],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getSocialRespList = async (filters) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    var sn = enqueueSnackbar(t("common:Cargando"), {
      persist: true,
    });
    try {
      const response = await axiosInstance.post(urls.socialresp.list, {
        ...filters,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespList: response.data,
        socialRespLoading: false,
      }));
      closeSnackbar(sn);
      dispatch(
        setSocialResponsibilityState({
          socialRespList: response.data.data,
        })
      );
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
      closeSnackbar(sn);
      enqueueSnackbar(t("common:Error al cargar la lista"), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const getCategoryList = async () => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.categories, {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespCategories: response.data,
        socialRespLoading: false,
      }));
      dispatch(
        setSocialResponsibilityState({
          socialRespCategories: response.data.data,
        })
      );
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
    }
  };

  const addSocialResp = async (data) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.add, {
        ...data,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Guardado"), {
        variant: "success",
      });
      var socialRespL = [response.data.data, ...socialRespList];
      dispatch(
        setSocialResponsibilityState({
          socialRespList: socialRespL,
          currentSocialResponsibility: response.data.data,
        })
      );
      return response.data.data;
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Error al guardar"), {
        variant: "error",
        // allow to close the snackbar
        persist: true,
      });
    }
  };

  const updateSocialResp = async (data) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.update, {
        ...data,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Guardado"), {
        variant: "success",
      });
      var socialRespL = socialRespList.map((item) => {
        if (item.id === data.id) {
          return response.data.data;
        }
        return item;
      });
      dispatch(
        setSocialResponsibilityState({
          socialRespList: socialRespL,
          currentSocialResponsibility: response.data.data,
        })
      );
      return response.data.data;
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Error al guardar"), {
        variant: "error",
        // allow to close the snackbar
        persist: true,
      });
    }
  };

  const deleteSocialResp = async (id) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.delete, {
        id,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Eliminado"), {
        variant: "success",
      });
      var socialRespL = socialRespList.filter((item) => item.id !== id);
      dispatch(
        setSocialResponsibilityState({
          socialRespList: socialRespL,
        })
      );
      return response.data.data;
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Error al eliminar"), {
        variant: "error",
        // allow to close the snackbar
        persist: true,
      });
    }
  };

  const getSocialRespDetail = async (id) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.detail, {
        id,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespLoading: false,
      }));
      if (response.status === 404) {
        return null;
      }
      dispatch(
        setSocialResponsibilityState({
          currentSocialResponsibility: response.data.data,
        })
      );
      return response.data.data;
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
    }
  };

  const exonerateSocialResp = async (id) => {
    setSocialRespState((prevState) => ({
      ...prevState,
      socialRespLoading: true,
    }));
    try {
      const response = await axiosInstance.post(urls.socialresp.exonerate, {
        id,
        exonerate: true,
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      });
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Exonerado"), {
        variant: "success",
      });
      var socialRespL = socialRespList.map((item) => {
        if (item.id === id) {
          return response.data.data;
        }
        return item;
      });
      dispatch(
        setSocialResponsibilityState({
          socialRespList: socialRespL,
          currentSocialResponsibility: response.data.data,
        })
      );
      return response.data.data;
    } catch (error) {
      setSocialRespState((prevState) => ({
        ...prevState,
        socialRespError: error,
        socialRespLoading: false,
      }));
      enqueueSnackbar(t("common:Error al exonerar"), {
        variant: "error",
        // allow to close the snackbar
        persist: true,
      });
    }
  };

  useEffect(() => {
    if (socialRespCategories.length === 0) {
      getCategoryList();
    }
  }, [socialRespCategories]);

  return {
    socialRespState,
    setSocialRespState,
    urls,
    getSocialRespList,
    addSocialResp,
    getCategoryList,
    getSocialRespDetail,
    updateSocialResp,
    deleteSocialResp,
    exonerateSocialResp,
  };
};

export default useSocialResp;
