import { lazy } from "react";
import MainLayout from "src/layouts/MainLayout";
import CorporateLayout from "src/views/arkadu/corporate/CorporateLayout";

export default [
  //   {
  //     exact: true,
  //     path: [
  //       "/:customerName/corporate/procedure/:tabName",
  //       "/:customerName/corporate/procedure",
  //     ],
  //     component: lazy(() => import("src/views/arkadu/corporate/procedure/admin")),
  //   },
  {
    exact: true,
    path: "/:customerName/corporate/monitor/calls/",
    layout: MainLayout,
    component: lazy(() =>
      import("src/views/arkadu/corporate/appointments/AppointmentsCallMonitor")
    ),
  },
  {
    exact: true,
    path: "/:customerName/corporate/appointment/search/",
    component: lazy(() =>
      import("src/views/arkadu/corporate/appointments/AppointmentsSearch")
    ),
  },
  {
    exact: true,
    path: "/:customerName/corporate/appointment/",
    component: lazy(() =>
      import("src/views/arkadu/corporate/appointments/AppointmentsAdminView")
    ),
  },
  //   {
  //     exact: true,
  //     path: "/:customerName/corporate/",
  //     component: lazy(() => import("src/views/arkadu/corporate/AdminIndex")),
  //   },
  {
    exact: true,
    path: "/:customerName/corporate/procedure/admin/:id",
    component: lazy(() =>
      import("src/views/arkadu/corporate/procedure/CorporateProcedureAdmin")
    ),
  },
  {
    exact: true,
    path: "/:customerName/corporate/procedure/:id",
    layout: CorporateLayout,
    component: lazy(() =>
      import("src/views/arkadu/corporate/procedure/ProcedureDetailView")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/corporate/procedure/preview/:id"],
    component: lazy(() =>
      import("src/views/arkadu/corporate/procedure/ProcedurePreview")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/corporate/procedure/"],
    component: lazy(() => import("src/views/arkadu/corporate/procedure/index")),
  },
  {
    exact: true,
    path: "/:customerName/corporate/schedule/:id",
    component: lazy(() =>
      import("src/views/arkadu/corporate/schedules/CorporateScheduleDetail")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/corporate/department/:id/:tabName",
      "/:customerName/corporate/department/:id",
    ],
    component: lazy(() =>
      import("src/views/arkadu/corporate/departments/CorporateDepartmentDetail")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/corporate/:tabName", "/:customerName/corporate/"],

    component: lazy(() => import("src/views/arkadu/corporate/index")),
  },
];
