import React, { createContext, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'src/store';

const initialAppState = {};
const AppContext = createContext({
  ...initialAppState
});

export const AppProvider = ({ appState, children }) => {
  const [state, setCurrentState] = useState(appState || initialAppState);
  const { customerName, appName, actionName } = useParams();
  const dispatch = useDispatch();
  const { customers, currentCustomer } = useSelector(state => state.customers);
  const { config, ...rest } = useSelector(state => state.settings);
  const settings = rest;
  const [currentApp, setCurrentApp] = useState();
  const [currentAction, setCurrentAction] = useState();


  // useEffect(() => {
  //   if (appName && config.apps && settingsLoaded) {
  //     var appList = config.apps.user;
  //     if (adminMode) {
  //       appList = config.apps.admin;
  //     }
  //     if (appList[appName]) {
  //       setCurrentApp(appList[appName]);
  //     }
  //   }
  // }, [appName, config, settingsLoaded]);

  // useEffect(() => {
  //   if (currentApp && actionName) {
  //     currentApp.models.map(m => {
  //       m.actions.map(ac => {
  //         if (ac.name === actionName) {
  //           setCurrentAction(ac);
  //         }
  //       });
  //     });
  //   }
  // }, [currentApp, actionName]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        currentApp: currentApp,
        currentAction: currentAction
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
