import { useSnackbar } from "notistack";
import { setBlogItems } from "src/slices/blog";

const { server } = require("src/coreConfig");
const { default: useAsyncRequest } = require("./useAsyncRequest");
const { useSelector, useDispatch } = require("src/store");

const blogUrls = {
  post: {
    list: `${server}/api/v2/blog/post/`,
    detail: `${server}/api/v2/blog/post/detail/`,
  },
  page: {
    list: `${server}/api/v2/blog/page/`,
    detail: `${server}/api/v2/blog/page/detail/`,
  },
};

const useBlog = () => {
  const { api } = useAsyncRequest();
  const { currentCustomer } = useSelector((state) => state.customers);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getMainBlog = async () => {
    try {
      const response = await api(blogUrls.page.list, {
        customer_id: currentCustomer.id,
      });
      if (response.data.res === 1) {
        dispatch(
          setBlogItems({
            ...response.data.data,
            loaded: true,
            loading: false,
          })
        );
        return response.data.data;
      } else {
        enqueueSnackbar(
          response.data?.message || "Error cargando las noticias",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getPosts = async (page) => {
    try {
      const response = await api(blogUrls.post.list, {
        customer_id: currentCustomer.id,
        page,
      });
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(
          response.data?.message || "Error cargando las noticias",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getPost = async (id) => {
    try {
      const response = await api(blogUrls.post.detail, {
        customer_id: currentCustomer.id,
        id,
      });
      if (response.data.res === 1) {
        dispatch(setBlogItems({ currentPost: response.data.data }));
        return response.data.data;
      } else {
        enqueueSnackbar(
          response.data?.message || "Error cargando la noticia",
          { variant: "error" }
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return {
    getMainBlog,
    getPosts,
    getPost,
  };
};

export default useBlog;
