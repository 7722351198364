import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { defaultErrorStates, defaultViewStates } from "src/utils/dataRenders";
import ErrorView from "src/views/errors/ErrorView";
const viewStates = {
  ...defaultViewStates,
};
const errorStates = {
  ...defaultErrorStates,
};
const BaseLayoutId = ({ children }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, setState] = useState(null);

  useEffect(() => {
    if (id) {
      try {
        var _id = parseInt(id);
      } catch (e) {
        console.log(e);
        setState(errorStates.invalidID);
        enqueueSnackbar(t("El id no es válido"), { variant: "error" });
      }
    } else {
      console.log(id);
      setState(errorStates.invalidID);
    }
  }, [id]);

  if (state === errorStates.invalidID) {
    return <ErrorView message={t("ID no válido ")} />;
  }

  return <>{children}</>;
};

export default BaseLayoutId;
