import { lazy } from "react";
import BaseLayoutId from "src/views/arkadu/BaseLayoutId";

export default [
  {
    exact: true,
    path: [
      "/:customerName/erp/accounting/:tabName",
      "/:customerName/erp/accounting/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/erp/accounting/AccountingIndex")
    ),
  },
  {
    exact: true,
    path: "/:customerName/erp/inventory/",
    component: lazy(() =>
      import("src/views/arkadu/erp/inventory/ErpInventoryIndex")
    ),
  },
  {
    exact: true,
    layout: BaseLayoutId,
    path: ["/:customerName/erp/staff/employee/detail/:id"],
    component: lazy(() =>
      import("src/views/arkadu/erp/staff/employee/ErpEmployerStaff")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/erp/staff/:tabName", "/:customerName/erp/staff/"],
    component: lazy(() => import("src/views/arkadu/erp/staff/ErpStaffIndex")),
  },
  {
    exact: true,
    path: "/:customerName/erp/budget/",
    component: lazy(() => import("src/views/arkadu/erp/budget/ErpBudgetIndex")),
  },
  {
    exact: true,
    path: "/:customerName/erp/suppliers/",
    component: lazy(() =>
      import("src/views/arkadu/erp/suppliers/ErpSuppliersIndex")
    ),
  },
  {
    exact: true,
    path: "/:customerName/erp/project/",
    component: lazy(() =>
      import("src/views/arkadu/erp/project/ErpProjectIndex")
    ),
  },
  {
    exact: true,
    path: ["/:customerName/erp/security/roles/detail/:id"],
    component: lazy(() =>
      import("src/views/arkadu/erp/security/ErpSecurityRoleDetail")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/erp/security/:tabName",
      "/:customerName/erp/security/",
    ],
    component: lazy(() =>
      import("src/views/arkadu/erp/security/ErpSecurityIndex")
    ),
  },
  {
    exact: true,
    path: "/:customerName/erp/",
    component: lazy(() => import("src/views/arkadu/erp/ErpIndex")),
  },
];
