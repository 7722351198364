import { lazy } from "react";

export default [
  {
    exact: true,
    path: "/:customerName/service/inscription",
    component: lazy(() =>
      import("src/views/arkadu/service/forms/NSInscription")
    ),
  },
  {
    exact: true,
    path: "/:customerName/service/invoice/:invoiceId",
    component: lazy(() =>
      import("src/views/arkadu/service/NSServiceInvoiceDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/service/searchOld",
    component: lazy(() => import("src/views/arkadu/service/extra/NSSearchOld")),
  },
  {
    exact: true,
    path: "/:customerName/service/payment/collector/statement/:id",
    component: lazy(() =>
      import("src/views/arkadu/service/NSPaymentCollectorStatement")
    ),
  },
  {
    exact: true,
    path: "/:customerName/service/payment/collector/:id",
    component: lazy(() =>
      import("src/views/arkadu/service/NSPaymentCollectorDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/service/service/:serviceId",
    component: lazy(() =>
      import("src/views/arkadu/service/NSServiceCustomerDetail")
    ),
  },
  {
    exact: true,
    path: "/:customerName/service/customers/inscription",
    component: lazy(() =>
      import("src/views/arkadu/service/NSCustomerInscription")
    ),
  },
  {
    exact: true,
    path: [
      "/:customerName/service/:tabName/:serviceId",
      "/:customerName/service/:tabName",
      "/:customerName/service/",
    ],
    component: lazy(() => import("src/views/arkadu/service/index")),
  },
];
