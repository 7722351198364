/* eslint-disable  */
//prettier-ignore
// we need to preserve the order of these imports and prevent to vscode on format on save to keep the order
/* ignore file */
import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';

import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill-new/dist/quill.snow.css';
import { Provider } from 'react-redux';
import App from 'src/App';
//import 'src/__mocks__';
import 'src/assets/css/prism.css';
// import 'src/mixins/chartjs';
// import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
//
import './i18n';



// import { CustomerProvider } from './contexts/CustomerContext';
// import { AuthProvider } from './contexts/AuthContext';
// import { Suspense } from 'react';
// import LoadingScreen from './components/LoadingScreen';

enableES5();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root')
// );

// serviceWorker.register();
// {
//   /* <AuthProvider> */
// }
// {
//   /* <CustomerProvider> */
// }
// {
//   /* <SettingsProvider> */
// }
// {
//   /* </SettingsProvider> */
// }
// {
//   /* </CustomerProvider> */
// }
// {
//   /* </AuthProvider> */
// }
